import { onReady } from "~/foundation/Events/onReady";
import { PhotoCompetitionService } from "./PhotoCompetitionService";
import { PhotoCompetitionOpenPhotoModal } from "./photo-competition-open-photo-modal";
import { PhotoCompetitionOpenUploadModal } from "./photo-competition-open-upload-modal.js";
import { addClass, hasClass, removeClass, toggleClass } from "~/foundation/Dom/classList";
import { breakpointIndex, currentWindowWidth, onWindowResize } from "~/foundation/Events/onWindowResize.js";
import MiniMasonry from "minimasonry";
import { addEvent } from "~/foundation/Events/events.js";
import { isRtl } from "~/foundation/Helpers/isRtl.js";
import { onClickOutside } from "~/foundation/Events/onClickOutside.js";
import { forEach } from "~/foundation/Helpers/forEach.js";
import { setStyles } from "~/foundation/Dom/setStyles.js";
import { utcToZonedTime } from "date-fns-tz";
import { isMobile } from "~/foundation/Helpers/isMobile";

export class PhotoCompetition {
    dom = {};
    galleryItems = [];
    photoCompetitionService = null;
    competition = null;
    userGalleryItemsCount = 0;
    maxUserPhotos = 1;

    dictionary = {};

    masonry = null;

    votingHasStarted = false;
    competitionHasEnded = true;
    votingIsActive = false;

    constructor(domReference, cookieEventAdded = false) {
        // View
        // src\Feature\PhotoCompetition\code\Areas\PhotoCompetition\Views\PhotoCompetition\PhotoCompetition.cshtml

        if (typeof domReference.dataset.src === "undefined") {
            this.setDomElements(domReference);
            this.setSettings();

            const {
                dictionary,
                competitionId,
                orderBy,
                filterBy,
                pageSize,
                termsLink,
                votingEndDate,
                votingStartDate,
            } = domReference.dataset;

            this.termsLink = termsLink;

            this.votingEndDate = votingEndDate;
            this.votingStartDate = votingStartDate;
            const utcNow = utcToZonedTime(new Date(), "UTC");
            this.votingHasStarted = new Date(this.votingStartDate) < utcNow;

            this.dictionary = JSON.parse(dictionary || "{}");

            if (!this.dom.galleryView) {
                console.error("Gallery View dom element is missing");
                return;
            }

            if (!this.dom.loadMoreButton) {
                console.error("Load more button is missing");
                return;
            }

            const contextItemId = this.dom.container.dataset.currentItemId;
            if (!contextItemId) {
                console.error("ContextItemId Id is missing");
                return;
            }

            if (!competitionId) {
                console.error("CompetitionId is missing");
                return;
            }

            this.photoCompetitionService = new PhotoCompetitionService({
                competitionId,
                contextItemId,
                pageSize: pageSize || 10,
                orderBy: orderBy || "popular",
                filterBy: filterBy || "all",
                orderByDescending: true,
                onGalleryItemFetch: this.onGalleryItemFetch.bind(this),
                loaderElement: this.dom.loader,
            });

            // https://gleam.io/docs/general/advanced/callback
            // Make sure it is enabled on the competition in "Install options"
            window.onGleamEvent = async (event) => {
                if (event.type === "entered") {
                    await this.onGleamUploaded(event.action, event.campaign);
                }

                /*
                   Logs an object like this:

                   {
                     type: "entered",
                     campaign: { key: "MqJxR", name: "My Newsletter Competition" },
                     action: { id: 4242, title: "Sign up for our newsletter" }
                   }

                   OR

                   {
                     type: "loaded",
                     campaign: { key: "MqJxR", name: "My Newsletter Competition" }
                   }
                */
            };

            onReady(() => {
                this.loadCompetition()
                    .then(() => this.initialize())
                    .catch((error) => {
                        console.error("Error", error);
                    });
            });
        } else {
            if (window.OneTrust && !cookieEventAdded) {
                window.OneTrust.OnConsentChanged(() => {
                    // const element = document.querySelector("[data-module='photo-competition']");
                    // if (element) {
                    //     const cookieConsentContainer = element.querySelector(".photo-competition__cookie-container");
                    //     if (cookieConsentContainer) {
                    //         cookieConsentContainer.style.display = "none";
                    //     }
                    //     const contentInner = element.querySelector(".photo-competition__content-inner");
                    //     if (contentInner) {
                    //         contentInner.style.display = "block";
                    //     }

                    //     void new PhotoCompetition(element, true);
                    // } else {
                    //     window.location.reload();
                    // }
                    // Jeppes work
                    window.location.reload();
                });
            }
        }
    }

    setDomElements(domReference) {
        this.dom.container = domReference;
        this.dom.galleryView = domReference.querySelector(
            "[data-gallery-view]"
        );
        this.dom.loadMoreButton = domReference.querySelector(
            "[data-gallery-loadmore]"
        );
        this.dom.loader = domReference.querySelector("[data-gallery-loader]");
        this.dom.linkList = domReference.querySelector(
            "[data-gallery-linklist]"
        );
        this.dom.orderByList = domReference.querySelector(
            "[data-gallery-orderby]"
        );
        this.dom.orderByItems = domReference.querySelectorAll(
            ".photo-competition__order-item"
        );
        this.dom.orderBySelectedValue =
            domReference.querySelector("[data-order-value]");
        this.dom.allPhotosLink = domReference.querySelector(
            "[data-all-photos-link]"
        );
        this.dom.myPhotosLink = domReference.querySelector(
            "[data-my-photos-link]"
        );
        this.dom.uploadModal = document.querySelector(
            "[data-photo-competition-upload-modal]"
        );
        this.dom.uploadModalContent = this.dom.uploadModal.querySelector(
            "[data-modal-content]"
        );
        this.dom.openUploadModal = domReference.querySelector(
            "[data-upload-button]"
        );
        this.dom.photoModal = document.querySelector(
            "[data-photo-competition-photo-modal]"
        );
        this.dom.svgHeart =
            '<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="love [#1489] copy 6" fill-rule="evenodd" clip-rule="evenodd" d="M11.9923 13.7587C11.4443 14.2245 10.7403 14.4815 10.0093 14.4815C9.27934 14.4815 8.57334 14.2255 8.00334 13.7447C4.40934 10.7169 2.12134 9.11046 2.00234 5.81569C1.87734 2.329 5.69734 1.05447 8.03934 3.9194C8.51334 4.49818 9.22534 4.83006 9.99334 4.83006C10.7683 4.83006 11.4853 4.49419 11.9603 3.9084C14.2513 1.08946 18.1733 2.29001 17.9943 5.91765C17.8343 9.14445 15.4583 10.8108 11.9923 13.7587ZM10.3863 2.71985C10.2883 2.8408 10.1403 2.90178 9.99334 2.90178C9.84934 2.90178 9.70634 2.8428 9.61034 2.72585C6.06734 -1.60753 -0.188658 0.550661 0.003342 5.88267C0.156342 10.1261 3.16434 12.2293 6.70634 15.2132C7.65334 16.0109 8.83134 16.4097 10.0093 16.4097C11.1903 16.4097 12.3703 16.0089 13.3153 15.2052C16.8183 12.2253 19.7883 10.1361 19.9913 6.00862C20.2613 0.557658 13.9203 -1.63152 10.3863 2.71985Z" fill="#323232"/></svg>';
        this.dom.svgHeartLiked =
            '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.3863 2.81012C10.2883 2.93108 10.1403 2.99205 9.99334 2.99205C9.84934 2.99205 9.70634 2.93307 9.61034 2.81612C6.06734 -1.51726 -0.188658 0.640932 0.003342 5.97294C0.156342 10.2164 3.16434 12.3196 6.70634 15.3034C7.65334 16.1011 8.83134 16.5 10.0093 16.5C11.1903 16.5 12.3703 16.0991 13.3153 15.2955C16.8183 12.3156 19.7883 10.2263 19.9913 6.09889C20.2613 0.647929 13.9203 -1.54125 10.3863 2.81012Z" fill="white"/></svg>';
        this.dom.svgChevron =
            '<svg viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg"><path fill="#323232" fill-rule="evenodd" d="m10.64-2.939 1.072 1.069-6.313 6.295 6.463 6.445-1.072 1.069-6.461-6.446-.117.117-1.073-1.068 7.501-7.481Z" transform="rotate(-90 7.5 4.5)"/></svg>';
    }

    setSettings() {
        this.enableMasonry = true;
    }

    getMasonryContainer() {
        return document.querySelector(".photo-competition__masonry-gallery");
    }

    async loadCompetition() {
        this.competition = await this.photoCompetitionService.getCompetition();
    }

    async initialize() {
        const utcNow = utcToZonedTime(new Date(), "UTC");
        const endsAtUtc = utcToZonedTime(
            new Date(this.competition.endsAt),
            "UTC"
        );
        this.competitionHasEnded = endsAtUtc < utcNow;

        this.votingIsActive =
            this.votingStartDate !== "" &&
            this.votingEndDate !== "" &&
            this.votingHasStarted &&
            utcNow < new Date(this.votingEndDate);

        this.likeablePhotos = this.votingIsActive;

        this.galleryItems =
            await this.photoCompetitionService.getGalleryItems();
        await this.renderView();

        const userGalleryItemsCount =
            this.photoCompetitionService.userGalleryItemsCount || 0;

        if (this.dom.uploadModal) {
            addEvent(this.dom.uploadModal, "keydown", (e) => {
                const key = e.charCode || e.keyCode || 0;

                if (key === 27) {
                    removeClass(
                        this.dom.uploadModal,
                        "photo-competition__upload-modal--open"
                    );
                }
            });
        }

        if (this.dom.photoModal) {
            addEvent(this.dom.photoModal, "keydown", (e) => {
                const key = e.charCode || e.keyCode || 0;

                if (key === 27) {
                    removeClass(
                        this.dom.photoModal,
                        "photo-competition__photo-modal--open"
                    );
                }
            });
        }

        if (this.dom.loadMoreButton) {
            this.dom.loadMoreButton.addEventListener("click", () => {
                this.loadMore();
            });
        }

        if (this.dom.allPhotosLink) {
            addEvent(this.dom.allPhotosLink, "click", () => {
                addClass(
                    this.dom.allPhotosLink,
                    "photo-competition__button--active"
                );
                if (this.dom.myPhotosLink) {
                    removeClass(
                        this.dom.myPhotosLink,
                        "photo-competition__button--active"
                    );
                }
                this.setOrdering("created", null);
            });
        }

        if (this.dom.myPhotosLink) {
            addEvent(this.dom.myPhotosLink, "click", () => {
                addClass(
                    this.dom.myPhotosLink,
                    "photo-competition__button--active"
                );
                if (this.dom.allPhotosLink) {
                    removeClass(
                        this.dom.allPhotosLink,
                        "photo-competition__button--active"
                    );
                }
                this.setOrdering("popular", "myphotos");
            });
        }

        if (this.dom.linkList) {
            addEvent(this.dom.linkList, "click", () => {
                toggleClass(
                    this.dom.linkList,
                    "photo-competition__dropdown--open",
                    !hasClass(
                        this.dom.linkList,
                        "photo-competition__dropdown--open"
                    )
                );
            });

            onClickOutside(
                this.dom.linkList,
                () => {
                    removeClass(
                        this.dom.linkList,
                        "photo-competition__dropdown--open"
                    );
                },
                window,
                false
            );
        }

        if (this.dom.orderByList) {
            addEvent(this.dom.orderByList, "click", () => {
                toggleClass(
                    this.dom.orderByList,
                    "photo-competition__dropdown--open",
                    !hasClass(
                        this.dom.orderByList,
                        "photo-competition__dropdown--open"
                    )
                );
            });

            addEvent(this.dom.orderByItems, "click", (e) => {
                addClass(
                    this.dom.allPhotosLink,
                    "photo-competition__button--active"
                );
                if (this.dom.myPhotosLink) {
                    removeClass(
                        this.dom.myPhotosLink,
                        "photo-competition__button--active"
                    );
                }

                const target = e.target;
                let sortBy = target.dataset.value;
                let filterBy = null;

                if (target.dataset.sortBy) {
                    sortBy = target.dataset.sortBy;
                    filterBy = target.dataset.value;
                }

                this.setOrdering(sortBy, filterBy);

                this.dom.orderBySelectedValue.innerText = target.innerText;
            });

            onClickOutside(
                this.dom.orderByList,
                () => {
                    removeClass(
                        this.dom.orderByList,
                        "photo-competition__dropdown--open"
                    );
                },
                window,
                false
            );
        }

        if (
            this.dom.openUploadModal &&
            this.dom.uploadModal &&
            !this.competitionHasEnded
        ) {
            this.dom.openUploadModal.dataset.uploadDisabled = (
                userGalleryItemsCount >= this.maxUserPhotos
            ).toString();

            this.dom.openUploadModal.style.display = "inline-block";
            void new PhotoCompetitionOpenUploadModal(
                this.dom.openUploadModal,
                this.competition.id,
                this.termsLink,
                this.dictionary
            );
        }

        let masonryBaseWidth = 384;
        let surroundingGutter = false;

        if (currentWindowWidth <= breakpointIndex.sm) {
            masonryBaseWidth = 300;
            surroundingGutter = false;
        }

        if (this.enableMasonry) {
            setTimeout(() => {
                this.masonry = new MiniMasonry({
                    container: this.getMasonryContainer(),
                    baseWidth: masonryBaseWidth,
                    wedge: true,
                    gutter: 24,
                    surroundingGutter,
                });
                window.masonry = this.masonry;

                if (isRtl) {
                    this.updateRtlLayout();
                }

                this.getMasonryContainer().style.visibility = "visible";
            }, 250);
        }

        if (isRtl) {
            onWindowResize(() => {
                this.updateRtlLayout();
            });
        }
    }

    updateRtlLayout = () => {
        // if RTL we loop through the gallery items and replace inline style transform with negative number - used same way as in article teasers!
        forEach(
            this.dom.container.querySelectorAll(
                ".photo-competition__gallery-item"
            ),
            (teaserElement) => {
                const transformNumbers = this.getTranslate3d(teaserElement);
                // get the first number and set minus in front
                transformNumbers[0] = `-${transformNumbers[0]}`;

                // set the style in the dom
                setStyles(teaserElement, {
                    transform: `translate3d(${transformNumbers.toString()})`,
                });
            }
        );
    };

    getTranslate3d = (el) => {
        const values = el.style.transform.split(/\w+\(|\);?/);
        if (!values[1] || !values[1].length) {
            return [];
        }

        return values[1].split(/,\s?/g);
    };

    async loadMore() {
        const nextPageGalleryItems =
            await this.photoCompetitionService.loadMore();
        this.galleryItems = this.galleryItems.concat(nextPageGalleryItems);
        await this.renderView(nextPageGalleryItems);
        if (this.enableMasonry) {
            this.masonry.layout();
            this.getMasonryContainer().style.visibility = "visible";

            if (isRtl) {
                this.updateRtlLayout();
            }
        }
    }

    async setOrdering(orderBy, filterBy = null) {
        this.galleryItems = await this.photoCompetitionService.setOrdering(
            orderBy,
            filterBy
        );
        await this.renderView();
        if (this.enableMasonry) {
            this.masonry.layout();
            this.getMasonryContainer().style.visibility = "visible";

            if (isRtl) {
                this.updateRtlLayout();
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    getTemplate(galleryItems, currentLength) {
        return galleryItems
            .map(
                (galleryItem) => `
            <div class="photo-competition__gallery-item " data-photo-id="${
                galleryItem.id
            }" ${
                    galleryItem.liked ? `data-gallery-item-liked="true"` : ""
                } data-likes-count="${galleryItem.likesCount}">
                <img src="${galleryItem.srcSmall}" data-gallery-src-big="${
                    galleryItem.srcBig
                }" />
                <div class="photo-competition__gallery-item-content photo-competition__gallery-item-content--${
                    galleryItem.status
                }">
                    <div class="photo-competition__gallery-item-content-start">
                        <div class="photo-competition__gallery-item-content-start-wrapper">
                            <p data-gallery-description>
                                ${galleryItem.description}
                            </p>
                            <span data-gallery-read-more>${this.dom.svgChevron}</span>
                        </div>
                        <p data-gallery-name>${galleryItem.name}</p>
                    </div>

                    ${
                        this.likeablePhotos && galleryItem.status === "approved"
                            ? //? `<button data-gallery-like-button class="photo-competition__like-button ${(galleryItem.liked ? "photo-competition__like-button--liked" : "")}">${galleryItem.likesCount} ${galleryItem.likesCount === 1 ? this.dictionary.like : this.dictionary.likes} ${galleryItem.liked ? this.dom.svgHeartLiked : this.dom.svgHeart}</button>`
                              `<button data-gallery-like-button class="photo-competition__like-button ${
                                  galleryItem.liked
                                      ? "photo-competition__like-button--liked"
                                      : ""
                              }">${
                                  galleryItem.liked
                                      ? this.dom.svgHeartLiked
                                      : this.dom.svgHeart
                              }</button>`
                            : ""
                    }

                    ${
                        galleryItem.status === "invalid"
                            ? `<div class="photo-competition__status">${this.dictionary.rejected}</div>`
                            : ""
                    }

                    ${
                        galleryItem.status === "valid"
                            ? `<div class="photo-competition__status">${this.dictionary.pending}</div>`
                            : ""
                    }
                </div>
            </div>
        `
            )
            .join("");
    }

    async renderView(nextPageGalleryItems = null) {
        let startIndex =
            this.galleryItems.length - this.photoCompetitionService.pageSize;
        if (startIndex < 0) {
            startIndex = 0;
        }
        const html = this.getTemplate(
            nextPageGalleryItems || this.galleryItems,
            startIndex
        );

        if (nextPageGalleryItems) {
            this.dom.galleryView.innerHTML += html;
        } else {
            this.dom.galleryView.innerHTML = html;
        }

        const galleryItemList = this.dom.galleryView.querySelectorAll(
            ".photo-competition__gallery-item, .photo-leaderboard__item"
        );

        galleryItemList.forEach((element) => {
            const likeButton = element.querySelector(
                "[data-gallery-like-button]"
            );

            if (likeButton) {
                likeButton.addEventListener("click", async () => {
                    const { photoId, galleryItemLiked, likesCount } =
                        element.dataset;

                    if (photoId) {
                        if (galleryItemLiked === "true") {
                            const newLikesCount = parseInt(likesCount) - 1;
                            await this.removeLikePhoto(
                                element,
                                likeButton,
                                photoId,
                                newLikesCount
                            );
                            element.removeAttribute("data-gallery-item-liked");
                        } else {
                            const newLikesCount = parseInt(likesCount) + 1;
                            await this.likePhoto(
                                element,
                                likeButton,
                                photoId,
                                newLikesCount
                            );
                            element.setAttribute(
                                "data-gallery-item-liked",
                                "true"
                            );
                        }
                    }
                });
            }

            this.initializePhotoModal(element);
            this.readMore(element);
        });

        const allImagesLoadPromises = [];

        this.dom.galleryView
            .querySelectorAll(".photo-competition__gallery-item img")
            .forEach((image) => {
                if (image.dataset.loaded !== "true") {
                    allImagesLoadPromises.push(
                        new Promise((resolve) => {
                            const onLoad = () => {
                                image.dataset.loaded = "true";
                                resolve();
                            };
                            const onError = () => {
                                console.error(
                                    `Image failed to load: ${image.src}`
                                );
                                resolve();
                            };
                            image.addEventListener("load", onLoad);
                            image.addEventListener("error", onError);
                        })
                    );
                }
            });
        return Promise.all(allImagesLoadPromises);
    }

    initializePhotoModal(element) {
        const listLikeElement = element.querySelector(
            "[data-gallery-like-button]"
        );
        void new PhotoCompetitionOpenPhotoModal(
            element,
            this.dictionary,
            this.photoCompetitionService,
            this.likeablePhotos,
            listLikeElement,
            {
                onLike: this.styleLikedButton,
                onRemoveLike: this.styleRemovedLikedButton,
            },
            !isMobile()
        );
    }

    readMore(galleryItemElement) {
        this.dom.description = galleryItemElement.querySelector("[data-gallery-description]");
        this.dom.readMore = galleryItemElement.querySelector("[data-gallery-read-more]");

        if (!this.dom.description || !this.dom.readMore) return;
        if (!isMobile()) {
            this.dom.readMore.style.display = "none";
            return;
        }

        const showReadMore = this.dom.description.scrollHeight > this.dom.description.offsetHeight;

        if (showReadMore) {
            this.dom.readMore.style.visibility = "visible";
        } else {
            this.dom.readMore.style.display = "none";
        }
    }

    async likePhoto(galleryItemElement, buttonElement, photoId, newLikesCount) {
        this.styleLikedButton(buttonElement, newLikesCount);

        galleryItemElement.dataset.galleryItemLiked = "true";
        galleryItemElement.dataset.likesCount = newLikesCount.toString();

        await this.photoCompetitionService.likePhoto(photoId);
    }

    async removeLikePhoto(
        galleryItemElement,
        buttonElement,
        photoId,
        newLikesCount
    ) {
        this.styleRemovedLikedButton(buttonElement, newLikesCount);

        galleryItemElement.dataset.galleryItemLiked = "false";
        galleryItemElement.dataset.likesCount = newLikesCount.toString();

        await this.photoCompetitionService.removeLikePhoto(photoId);
    }

    styleLikedButton(buttonElement, newLikesCount) {
        if (buttonElement && typeof newLikesCount === "number") {
            addClass(buttonElement, "photo-competition__like-button--liked");
            //buttonElement.innerHTML = `${newLikesCount} ${newLikesCount === 1 ? this.dictionary.like : this.dictionary.likes} ${this.dom.svgHeartLiked}`;
            buttonElement.innerHTML = `${this.dom.svgHeartLiked}`;
        }
    }

    styleRemovedLikedButton(buttonElement, newLikesCount) {
        if (buttonElement && typeof newLikesCount === "number") {
            removeClass(buttonElement, "photo-competition__like-button--liked");
            //buttonElement.innerHTML = `${newLikesCount} ${newLikesCount === 1 ? this.dictionary.like : this.dictionary.likes} ${this.dom.svgHeart}`;
            buttonElement.innerHTML = `${this.dom.svgHeart}`;
        }
    }

    onGalleryItemFetch(data) {
        if (this.dom.loadMoreButton) {
            if (!data.isLastPage) {
                this.dom.loadMoreButton.style.display = "inline-block";
            } else {
                this.dom.loadMoreButton.style.display = "none";
            }
        }

        if (this.dom.orderByList) {
            const mylikesElement = this.dom.orderByList.querySelector(
                "[data-value='mylikes']"
            );
            if (this.dom.orderByList && mylikesElement) {
                if (data.myLikesCount === 0) {
                    mylikesElement.style.display = "none";
                }
            }
        }

        if (this.dom.myPhotosLink) {
            if (data.myPhotosCount === 0) {
                addClass(
                    this.dom.myPhotosLink,
                    "photo-competition__button--hidden"
                );
            } else {
                removeClass(
                    this.dom.myPhotosLink,
                    "photo-competition__button--hidden"
                );
            }
        }

        if (this.dom.openUploadModal) {
            if (data.myPhotosCount >= this.maxUserPhotos) {
                addClass(this.dom.openUploadModal, "restrict-upload");
            }
        }

        if (
            this.dom.openUploadModal &&
            this.dom.uploadModalContent &&
            data.myPhotosCount >= this.maxUserPhotos
        ) {
            this.dom.openUploadModal.dataset.uploadDisabled = "true";
            this.dom.uploadModalContent.innerHTML = `<div class="photo-competition__restrict-upload-content"><p>${this.dictionary.restrictedUploads}</p></div>`;
        }
    }

    // eslint-disable-next-line no-unused-vars
    async onGleamUploaded(action, campaign) {
        await this.photoCompetitionService.clearCache();

        const closeButton = document.querySelector("[data-close-upload-modal]");
        if (closeButton) {
            closeButton.click();
        }

        if (this.dom.myPhotosLink) {
            this.dom.myPhotosLink.click();
        }
    }
}
